@use "../../variables" as css;

.setting-card-screen {

    .noScrollTextarea {
        resize: none;
        overflow: hidden;
    }
}

.card-purchase-screen {

    .col-6-40 {
        width: calc(50% - 3px);
        border-radius: 4px;
    }
}

#modal-card-of-group {
    .modal-content {
        width: 90vw;
    }
}

.card-hold-img {
    position: relative;
    .card-lock {
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: rgb(255 255 255 / 80%);
      color: #FF435A;
      font-weight: normal;
    }
}

.border-cs-dashed-bottom {
    border-bottom: dashed 1px var(--bs-border-color)
}

@use './variables' as css;

// all
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
}

html,
body {
  .daterangepicker {
    z-index: 555;
  }
}

body,
#root,
.wrapper {
  min-height: 100vh !important;
  bottom: 0;
}

// link
a {
  color: map-get($map: css.$colors, $key: 4) !important;
  text-decoration: none !important;
}

.underline-link {
  text-decoration: underline !important;
}

//paragraph
p {
  white-space: pre-line
}

// input
input:not([type='checkbox']):not([type='radio']),
textarea,
select {
  border: 0.5px solid map-get($map: css.$colors, $key: light-gray);
  border-radius: 5px;
  font-size: map-get($map: css.$fs, $key: fs-input);
  padding: 5px;
  outline: none;

  &:focus {
    border-color: map-get($map: css.$colors, $key: 3);
  }
}

input:not([type='checkbox']):not([type='radio']),
select {
  height: css.$height-default;
}

// button 

button {
  height: css.$height-default;
  border-radius: css.$radius-default;

  &.not-allowed {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
}

// border-radius
.radius-default {
  border-radius: css.$radius-default !important;
}

.radius-div {
  border-radius: css.$radius-div !important;
}

// shadow
.shadow-box {
  box-shadow: css.$shadow-box-default;
}

// icon as button
.btn-icon-input {
  width: fit-content;
  border: none;
  background-color: inherit;
  font-size: 20px;
  height: 30px;
  float: right;
  position: relative;
  margin-right: 10px;
  margin-top: -32.5px;
  cursor: pointer;
}

// css button with icon
.btn-icon {
  width: 25px;
  height: 25px;
  font-size: 16px;
  border: none;
  background-color: transparent !important;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.drop-item {
  position: relative;

  .drop-remove {
    position: absolute;
    bottom: 2px;
    right: -35px;
  }
}

@each $color, $value in (orange: orange, red: red, green: green, blue: rgb(2, 174, 201), gray: #5c5c5c) {
  .btn-icon-#{$color} {
    color: $value !important;
    border: 1px solid $value !important;
  }
}

// css hover
.hover-underline:hover {
  text-decoration: underline !important;
}

// font size
@each $f,
$value in css.$fs {
  .#{$f} {
    font-size: $value;
  }
}

// form
form {
  label {
    font-weight: 700;

    &:hover {
      cursor: auto;

      img {
        cursor: pointer;
      }
    }
  }

  .form-switch {
    margin: 0;

    .form-check-input {
      height: 1.5rem;
      width: calc(2rem + 0.75rem);
      border-radius: 3rem;
      outline: none;
      box-shadow: none;
    }
  }
}

// label field required
.field-required::after {
  content: " *";
  color: red;
}

// accordions
.accordion-button {
  border: 1px solid map-get($map: css.$colors, $key: light-gray) !important;

  &:not(.collapsed) {
    box-shadow: 0px 2px map-get($map: css.$colors, $key: 3) !important;
  }
}

// class col
.extra-col {
  position: relative;
  width: 100%;
}

.col-0-5 {
  @extend .extra-col;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.col-1-5 {
  @extend .extra-col;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-2-5 {
  @extend .extra-col;
  flex: 0 0 20.833333325%;
  max-width: 20.833333325%;
}

.col-3-5 {
  @extend .extra-col;
  flex: 0 0 29.166666655%;
  max-width: 29.166666655%;
}

.col-4-5 {
  @extend .extra-col;
  flex: 0 0 37.499999985%;
  max-width: 37.499999985%;
}

.col-5-5 {
  @extend .extra-col;
  flex: 0 0 45.833333315%;
  max-width: 45.833333315%;
}

.col-6-5 {
  @extend .extra-col;
  flex: 0 0 54.166666645%;
  max-width: 54.166666645%;
}

.col-7-5 {
  @extend .extra-col;
  flex: 0 0 62.499999975%;
  max-width: 62.499999975%;
}

.col-8-5 {
  @extend .extra-col;
  flex: 0 0 70.833333305%;
  max-width: 70.833333305%;
}

.col-9-5 {
  @extend .extra-col;
  flex: 0 0 79.166666635%;
  max-width: 79.166666635%;
}

.col-10-5 {
  @extend .extra-col;
  flex: 0 0 87.499999965%;
  max-width: 87.499999965%;
}

.col-11-5 {
  @extend .extra-col;
  flex: 0 0 95.8333333%;
  max-width: 95.8333333%;
}


// css common
@each $color,
$value in css.$colors {
  .text-color-cs-#{$color} {
    color: $value;
  }

  .border-bottom-think-color-cs-#{$color} {
    border-bottom: 3px solid $value !important;
  }

  .border-bottom-thin-color-cs-#{$color} {
    border-bottom: 1px solid $value !important;
  }

  .btn-cs-#{$color} {
    background-color: inherit;
    color: $value !important;
    border: 1px solid $value !important;
  }

  .btn-bg-cs-#{$color} {
    background-color: $value !important;
    color: map-get($map: css.$colors, $key: white) !important;
    border: 1px solid $value !important;
  }

  .bg-cs-#{$color} {
    background-color: $value !important;
  }
}

@for $i from 0 through 100 {

  .fs-cs-#{$i} {
    font-size: #{$i}px !important;

    * {
      font-size: #{$i}px !important;
    }
  }

  .border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }

  .mg-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mg-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .mg-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mg-r-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mg-tb-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .mg-lr-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .pd-#{$i} {
    padding: #{$i}px !important;
  }

  .pd-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pd-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pd-l-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pd-r-#{$i} {
    padding-right: #{$i}px !important;
  }

  .pd-tb-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .pd-lr-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  .mg-#{$i} {
    margin: #{$i}px !important;
  }

  .flex-gap-#{$i} {
    gap: #{$i}px !important;
  }
}

$max: 1200;
$step: 5;

@for $i from 0 through calc($max/$step) {
  $value: $i * $step;

  .min-width-#{$value} {
    min-width: #{$value}px;
  }

  .max-width-#{$value} {
    max-width: #{$value}px;
  }

  .width-#{$value} {
    width: #{$value}px;
  }

  .min-height-#{$value} {
    height: #{$value}px;
  }

  .max-height-#{$value} {
    max-height: #{$value}px;
  }

  .height-#{$value} {
    height: #{$value}px;
  }
}

// tabs css
.tabs-container {
  .tab-list {

    .tab {
      text-align: center;
      font-weight: 700;
      background-color: #f0f0f09b;
      padding-top: 13px;
      padding-bottom: 13px;
      border-radius: 10px 10px 0px 0px;
      position: relative;
      border: none !important;
      outline: none !important;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: map-get($map: css.$colors, $key: light-gray);
      }

      &.react-tabs__tab--selected {
        border: 1px solid map-get($map: css.$colors, $key: light-gray) !important;
        border-bottom: none !important;
        background-color: map-get($map: css.$colors, $key: white);

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          background-color: map-get($map: css.$colors, $key: 1);
        }
      }
    }
  }
}

// css scroll

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get($map: css.$colors, $key: white);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($map: css.$colors, $key: 3);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($map: css.$colors, $key: 3);
}

//custom-select-filter
.custom-filter-select {
  .btn {
    border: 0.5px solid #D5D5D5 !important;
    background-color: white !important;
  }

  .btn:hover {
    color: none !important;
    background-color: white !important;
    border-color: #D5D5D5 !important;
  }

  button.btn.dropdown-toggle.btn-light {
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
    border-color: #D5D5D5 !important;
  }

  button.btn.dropdown-toggle.btn-light.show {
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
    border-color: #140808 !important;
  }

  .dropdown-item {
    color: black !important
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #0d6efd !important;
    color: white !important
  }

  .inner {
    max-height: 350px !important;
  }

  .dropdown-menu {
    max-width: 100%;
  }
}

// button common
.btn-default {
  min-width: 120px !important;
  border-radius: css.$radius-default;
}

.btn-search-back {
  border: 2px solid map-get($map: css.$colors, $key: 1) !important;
  background-color: map-get($map: css.$colors, $key: white) !important;
  color: map-get($map: css.$colors, $key: 1) !important;
  font-weight: 600;
}

.btn-close-custom {
  border: 1px solid map-get($map: css.$colors, $key: 3) !important;
  background-color: map-get($map: css.$colors, $key: white) !important;
}

.btn-cancel {
  background-color: map-get($map: css.$colors, $key: light-gray) !important;
  border: none !important;
  color: map-get($map: css.$colors, $key: white) !important;
  font-weight: 500;
}

.btn-lock {
  border: 1px solid map-get($map: css.$colors, $key: 1) !important;
  background-color: map-get($map: css.$colors, $key: white);
  color: map-get($map: css.$colors, $key: 1) !important;
  font-weight: 500;
}

// reset input
input.reset-auto-fill {

  // remove auto fill
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 9999s ease-in-out 0s;
  }
}
// variable

// font size
$fs: (
    fs-default: 14px,
    fs-heading-large: 32px,
    fs-heading-normal: 24px,
    fs-heading-small: 18px,
    fs-input: 13px,
    fs-error: 13px,
    fs-label: 16px
);

// color
$colors: (
    1: #F59A23,
    2: #F5F5F5,
    3: #140808,
    4: #0077B6,
    5: #000000,
    6: #aaa,
    7: #ffebd4,
    light-gray: #D5D5D5,
    medium-gray-opacity: #e5e5e5b0,
    gray: #5c5c5c,
    white: #FFFFFF,
    red: red,
    green: rgb(23, 215, 23),
    bold-green: #4d7b07,
    label-1: #7F7F7F,
    bg-1: rgba(240, 240, 240, 0.6)
);

// height
$height-default: 35px;

// border-radius
$radius-default: 5px;
$radius-div: 10px;

// shadow
$shadow-box-default: rgba(0, 0, 0, 0.18) 0px 2px 4px;

// custom bootstrap
// dropdowns
$dropdown-link-active-bg: map-get($map: $colors, $key: 3);
// accordion
$accordion-button-color: map-get($map: $colors, $key: 3);
$accordion-button-bg: map-get($map: $colors, $key: white);
$accordion-button-active-bg: map-get($map: $colors, $key: white);
$accordion-button-active-color: map-get($map: $colors, $key: 3);
$accordion-button-focus-border-color: map-get($map: $colors, $key: 3);
$accordion-button-focus-box-shadow: none;
$accordion-icon-color: map-get($map: $colors, $key: 3);
$accordion-icon-active-color: map-get($map: $colors, $key: 3);
$accordion-border-width: 0px;
$accordion-button-padding-y: 20px;